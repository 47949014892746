<template>
  <div>
    <a-form :form="form" class="card-header p-5" @submit="addActivity">
      <div class="row">
        <div class="col-md-8">
          <a-form-item>
            <a-select
              v-decorator="[
                'activity',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('paiement.activiteRequis'),
                    },
                  ],
                },
              ]"
              show-search
              :placeholder="$t('paiement.listeActivites')"
              style="width: 100%"
              option-filter-prop="children"
              :filter-option="filterOption"
              @change="(v) => selectActivity(v)"
            >
              <a-select-option
                v-for="activtiy in activities"
                :key="activtiy._id"
                :value="activtiy._id"
              >
                {{ activtiy.designation }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
        <div class="col-md-4">
          <a-tag color="green">
            {{ $t("inscription.inscription") }} :
            <b>{{ parseInt(activityInfos.inscription) || "--" }}</b></a-tag
          ><br />
          <a-tag color="green">
            {{ $t("inscription.mensuel") }} :
            <b>{{ parseInt(activityInfos.monthly) || "--" }} </b></a-tag
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <a-form-item>
            <a-date-picker
              :allowClear="false"
              :disabled-date="disabledDate"
              v-decorator="[
                'start',
                {
                  defaultValue: moment(Date.now()),
                  rules: [
                    {
                      required: true,
                      message: $t('paiement.dateDebutRequis'),
                    },
                  ],
                },
              ]"
              :format="dateFormat"
              style="width: 100%"
            />
          </a-form-item>
        </div>
        <div class="col-md-6">
          <a-form-item>
            <a-date-picker
              :disabled-date="disabledEndDate"
              v-decorator="[
                'end',
                {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
              :format="dateFormat"
              style="width: 100%"
            />
          </a-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 mt-5">
          <a-button
            type="primary"
            style="width: 100%"
            htmlType="submit"
            :loading="loadingClasse"
            :disabled="loadingClasse"
            icon="tags"
            :ghost="false"
          >
            {{ $t("action.ajouter") }}
          </a-button>
        </div>
      </div>
    </a-form>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import { mapState } from "vuex";
import store from "store";

const actvitiyFeesFormater = (activityFees, studentId, studentList) => {
  const getClassRoom = (studentId, studentList) => {
    try {
      return studentList.filter((s) => s._id == studentId)[0].classRoomName;
    } catch (error) {
      return " ";
    }
  };
  let status = "impayed";
  if (activityFees.inscription) {
    if (!activityFees.activity.once && activityFees.months) {
      status = "payed";
      for (const [key, value] of Object.entries(activityFees.months)) {
        if (!value) {
          status = "impayed";
          break;
        }
      }
    }
  }
  return {
    status,
    _id: activityFees._id,
    fullName:
      activityFees.student.firstName + " " + activityFees.student.lastName,
    activity: activityFees.activity.designation,
    class: getClassRoom(studentId, studentList),
  };
};
const dateRange = (startDate, endDate) => {
  var start = moment(startDate).format("YYYY-MM-DD").split("-");
  var end = moment(endDate).format("YYYY-MM-DD").split("-");
  var startYear = parseInt(start[0]);
  var endYear = parseInt(end[0]);
  var dates = [];

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month < 10 ? "0" + month : month;
      dates.push([i, displayMonth, "01"].join("-"));
    }
  }
  return dates;
};

const monthly = (start, end) => {
  let monthObj = Object();
  dateRange(start, end).forEach((date) => (monthObj[date] = false));
  return monthObj;
};
export default {
  computed: mapState(["settings"]),
  props: {
    studentId: {
      required: true,
    },
  },
  created() {
    this.form = this.$form.createForm(this, { name: "addActivityForm" });
    apiClient
      .post("/activities/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            designation: 1,
            start: 1,
            end: 1,
            inscription: 1,
            monthly: 1,
            once: 1,
          },
        },
      })
      .then((res) => (this.activities = res.data))
      .catch((e) => this.$message.error(this.$t("error.aucActivite")));
  },
  data() {
    return {
      loadingClasse: false,
      dateFormat: "DD-MM-YYYY",
      studentsData: [],
      studentList: [],
      classList: [],
      activities: [],
      actvitiyFees: [],
      activitySelected: {
        start: moment(Date.now()),
        end: null,
      },
      activityInfos: {},
    };
  },
  methods: {
    moment,
    disabledDate(current) {
      if (!this.activitySelected.end) {
        const schoolarYears = this.settings.schoolarYears;
        const activeSchoolarYear = store.get("app.settings.activeSchoolarYear");
        const schoolarYear = schoolarYears.filter(
          (sc) => sc._id == activeSchoolarYear
        )[0];
        this.activitySelected.end = moment(schoolarYear.end);
      }
      if (!this.activitySelected.start) {
        const schoolarYears = this.settings.schoolarYears;
        const activeSchoolarYear = store.get("app.settings.activeSchoolarYear");
        const schoolarYear = schoolarYears.filter(
          (sc) => sc._id == activeSchoolarYear
        )[0];
        this.activitySelected.start = moment(schoolarYear.start);
      }
      const { start, end } = this.activitySelected;
      return (current && current.isAfter(end)) || current.isBefore(start);
      //return current && current < moment().endOf("day");
    },
    disabledEndDate(current) {
      if (!this.activitySelected.end) {
        const schoolarYears = this.settings.schoolarYears;
        const activeSchoolarYear = store.get("app.settings.activeSchoolarYear");
        const schoolarYear = schoolarYears.filter(
          (sc) => sc._id == activeSchoolarYear
        )[0];
        this.activitySelected.end = moment(schoolarYear.end);
      }
      if (!this.activitySelected.start) {
        const schoolarYears = this.settings.schoolarYears;
        const activeSchoolarYear = store.get("app.settings.activeSchoolarYear");
        const schoolarYear = schoolarYears.filter(
          (sc) => sc._id == activeSchoolarYear
        )[0];
        this.activitySelected.start = moment(schoolarYear.start);
      }
      let { start, end } = this.activitySelected;
      if (this.form.getFieldValue("start"))
        start = this.form.getFieldValue("start");
      return (current && current.isAfter(end)) || current.isBefore(start);
      //return current && current < moment().endOf("day");
    },
    selectActivity(val) {
      const activity = [...this.activities].filter((ac) => ac._id == val);
      const { start, end } = activity[0];
      this.activityInfos = activity[0];
      this.activitySelected.start = moment(start);
      this.activitySelected.end = moment(end);
    },
    validateMonths(activityFees, range) {
      let { start, end } = range;
      let result = true;
      if (!end) end = activityFees.activity.end;
      const newMonthly = monthly(start, end);
      const existMonthly = activityFees.months;
      try {
        if (
          Object.keys(existMonthly).forEach((value) => {
            if (Object.keys(newMonthly).includes(value)) result = false;
          })
        )
          result = false;
      } catch {
        result = false;
      }
      return result;
    },
    addActivity(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loadingClasse = true;

          let already = false;
          const data = {
            start: values.start,
            student: this.studentId,
            activity: values.activity,
            inscription: values.inscription,
          };
          apiClient
            .post("/activitiesfees/filter", {
              query: {
                student: this.studentId,
                activity: values.activity,
                status: "active",
              },
            })
            .then((res) => {
              res.data.forEach((activityFees) => {
                if (
                  !this.validateMonths(activityFees, {
                    start: values.start,
                    end: values.end,
                  })
                )
                  already = true;
              });
              if (already) {
                this.loadingClasse = false;
                this.$message.warning(
                  this.$t("error.eleveDejaInscritPourMois")
                );
              } else {
                apiClient
                  .put("/students/activity/payment", {
                    data: values.end
                      ? {
                          ...data,
                          end: values.end,
                        }
                      : data,
                  })
                  .then((res) => {
                    this.$message.success(this.$t("success.inscriAjout"));
                    this.$emit("updateActivityInscription");
                    this.actvitiyFees.push(
                      actvitiyFeesFormater(
                        res.data,
                        res.data.student._id,
                        this.studentList
                      )
                    );
                  })
                  .catch((e) => {
                    console.error(e.stack);
                    this.$message.error(this.$t("error.ajout"));
                  })
                  .finally(() => (this.loadingClasse = false));
              }
            });
        }
      });
      //this.form.resetFields();
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
