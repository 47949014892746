<template>
  <div class="d-flex flex-wrap flex-column align-items-center">
    <div
      class="kit__utils__avatar kit__utils__avatar--size64 mb-3"
      v-if="profileImage"
    >
      <img :src="settings.base_url + '/' + profileImage" :alt="fullName" />
    </div>
    <div class="text-center">
      <div class="text-dark font-weight-bold font-size-28">{{ fullName }}</div>
      <div class="text-uppercase font-size-18 mb-3">
        {{ calculateAge(birthDay) }} ans
      </div>
      <button
        v-if="classRoom"
        type="button"
        class="btn btn-primary btn-disabled"
        :class="$style.btnWithAddon"
        @click="go"
      >
        <span :class="$style.btnAddon">
          <i class="fa fa-user" :class="$style.btnAddonIcon" />
        </span>
        {{ classRoom }}
      </button>
      <button
        v-else
        type="button"
        class="btn btn-secondary btn-disabled"
        :class="$style.btnWithAddon"
      >
        <span :class="$style.btnAddon">
          <i class="fa fa-user" :class="$style.btnAddonIcon" />
        </span>
        {{ $t("profil.nonInscrit") }}
      </button>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import router from "@/router";
import { mapState } from "vuex";

export default {
  name: "ProfileCard",
  computed: mapState(["settings"]),

  props: {
    profileImage: {
      required: false,
    },
    fullName: {
      required: false,
    },
    classRoom: {
      required: false,
    },
    classRoomId: {
      required: false,
    },
    birthDay: {
      required: true,
    },
  },
  methods: {
    go() {
      router.push("/inscriptionScolarite/" + this.classRoomId);
    },
    calculateAge: function (date) {
      const birthday = new Date(date);
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
