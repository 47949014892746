<template>
  <div class="card">
    <a-page-header
      v-if="data.type == 'activity'"
      style="border: 1px solid rgb(235, 237, 240)"
      class="card-header"
      :title="data.typeData.designation"
    >
      <div class="row">
        <div class="col-md-4">
          <b>{{ $t("all.startDate") }}: </b
          >{{ moment(data.typeData.start).format("DD-MM-YYYY") }}
        </div>
        <div class="col-md-4">
          <b>{{ $t("all.endDate") }}: </b
          >{{
            data.typeData.start
              ? moment(data.typeData.end).format("DD-MM-YYYY")
              : "-"
          }}
        </div>
        <div class="col-md-4">
          <a-select
            @change="selectMode"
            show-search
            style="width: 100%"
            option-filter-prop="children"
            :placeholder="$t('recette.typePaiement')"
            :filter-option="filterOption"
          >
            <a-select-option
              :value="item._id"
              :name="item.name"
              v-for="item in receiptTypes"
              :key="item._id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-4">
          <b>{{ $t("recette.fraisInscri") }}: </b
          >{{ data.typeData.inscription }} DT
        </div>
        <div class="col-md-4" v-if="!data.typeData.once">
          <b>{{ $t("recette.fraisMensuel") }}: </b
          >{{ data.typeData.monthly + " DT" }}
        </div>
        <div class="col-md-4" v-else>
          <b>{{ $t("recette.fraisMensuel") }}: </b>{{ $t("all.non") }} ({{
            $t("etablissement.seuleFois")
          }})
        </div>
        <a-dropdown class="col-md-4">
          <a-menu
            slot="overlay"
            @click="
              (v) => {
                validPayment(v);
              }
            "
          >
            <a-menu-item key="Receipt">
              <a-icon type="user" />{{
                $t("recette.recuPaiement")
              }}</a-menu-item
            >
            <a-menu-item key="Bill">
              <a-icon type="user" />{{
                $t("recette.facturePaiement")
              }}</a-menu-item
            >
          </a-menu>
          <a-button :loading="loadingValider" :disabled="loadingValider">
            {{ $t("recette.valider") }} <a-icon type="down" />
          </a-button>
        </a-dropdown>
      </div>
    </a-page-header>
    <a-page-header
      v-else
      style="border: 1px solid rgb(235, 237, 240)"
      class="card-header"
      :title="data.typeData.name"
    >
      <div class="row">
        <div class="col-md-4">
          <b>{{ $t("all.startDate") }}: </b
          >{{ moment(data.typeData.start).format("DD-MM-YYYY") }}
        </div>
        <div class="col-md-4">
          <b>{{ $t("all.endDate") }}: </b
          >{{
            data.typeData.start
              ? moment(data.typeData.end).format("DD-MM-YYYY")
              : "-"
          }}
        </div>
        <div class="col-md-4">
          <a-select
            @change="selectMode"
            show-search
            style="width: 100%"
            option-filter-prop="children"
            :placeholder="$t('recette.typePaiement')"
            :filter-option="filterOption"
          >
            <a-select-option
              :value="item._id"
              v-for="item in receiptTypes"
              :key="item._id"
              :name="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-4">
          <b>{{ $t("recette.fraisInscri") }}: </b
          >{{ data.typeData.inscription }}DT
        </div>
        <div class="col-md-4">
          <b>{{ $t("recette.fraisMensuel") }}: </b
          >{{ data.typeData.monthly + "DT" }}
        </div>
        <a-dropdown class="col-md-4">
          <a-menu
            slot="overlay"
            @click="
              (v) => {
                validPayment(v);
              }
            "
          >
            <a-menu-item key="Receipt" :disabled="loadingValider">
              <a-icon type="file-pdf" />{{
                $t("recette.recuPaiement")
              }}</a-menu-item
            >
            <a-menu-item key="Bill" :disabled="loadingValider">
              <a-icon type="file-pdf" />{{
                $t("recette.facturePaiement")
              }}</a-menu-item
            >
          </a-menu>
          <a-button :loading="loadingValider" :disabled="loadingValider">
            Valider <a-icon type="down" />
          </a-button>
        </a-dropdown>
      </div>
    </a-page-header>

    <div
      class="row mt-2 mb-2"
      v-if="originalReduction.monthly || originalReduction.inscription"
    >
      <div class="col-md-4 center pl-4">
        <b>{{ $t("recette.reducRepet") }} :</b>
      </div>
      <div class="col-md-4 center">
        <b> {{ $t("recette.reducInscri") }}</b>
        <a-input-number
          :min="0"
          :max="100"
          :disabled="true"
          :default-value="originalReduction.inscription"
          :v-model="data.reduction.inscription"
        />
      </div>
      <div class="col-md-4 center">
        <b> {{ $t("recette.reductionMensuel") }}</b>
        <a-input-number
          :min="0"
          :max="100"
          :disabled="true"
          :default-value="originalReduction.monthly"
          :v-model="data.reduction.monthly"
        />
      </div>
    </div>
    <!--reduction en TND -->
    <div class="row mt-2 mb-2" v-if="reductionType === 'TND'">
      <div class="col-md-4 center pl-4">
        <b>{{ $t("recette.reducFact") }} (TND)</b>
      </div>
      <div class="col-md-1 center">
        <a-input-number
          :min="0"
          :v-model="TndOnBillReduction"
          :default-value="0"
          @change="
            (v) => {
              TndOnBillReduction = v;
              showTotal(null, true);
            }
          "
        />
      </div>
      <div class="col-md-3 mt-3">
        <ul>
          <li>
            {{ $t("recette.avantReduction") }} :
            <b>{{ total.bReduction.toFixed(2) }}</b>
          </li>
          <li>
            {{ $t("recette.apresReduction") }} :
            <b>{{ total.aReduction.toFixed(2) }}</b>
          </li>
          <li>
            {{ $t("recette.reduction") }} (%) :
            <b>{{ onBillReduction.toFixed(2) }}</b>
          </li>
        </ul>
      </div>
      <div class="col-md-4 center">
        <a-button @click="checkEmALL">
          <a-icon type="dollar" />{{ $t("recette.paiementTotal") }}
        </a-button>
        <br />
        <a-switch
          v-if="dataAvance.length > 0"
          v-model="withAvance"
          checked-children="Payer avec avance"
          un-checked-children="Payer sans
        avance"
          class="mt-3"
        />
      </div>
    </div>
    <!--reduction % -->
    <div class="row mt-2 mb-2" v-else>
      <div class="col-md-4 center pl-4">
        <b>{{ $t("recette.reducFact") }} (%)</b>
      </div>
      <div class="col-md-1 center">
        <a-input-number
          :min="0"
          :max="100"
          :v-model="onBillReduction"
          :default-value="0"
          @change="
            (v) => {
              onBillReduction = v;
              showTotal(null, true);
            }
          "
        />
      </div>
      <div class="col-md-3 mt-3">
        <ul>
          <li>
            {{ $t("recette.avantReduction") }} :
            <b>{{ total.bReduction.toFixed(2) }}</b>
          </li>
          <li>
            {{ $t("recette.apresReduction") }} :
            <b>{{ total.aReduction.toFixed(2) }}</b>
          </li>
          <li>
            {{ $t("recette.reduction") }} (TND) :
            <b>{{ total.reduction.toFixed(2) }}</b>
          </li>
        </ul>
      </div>
      <div class="col-md-4 center">
        <a-button @click="checkEmALL">
          <a-icon type="dollar" />{{ $t("recette.paiementTotal") }}
        </a-button>
        <br />
        <a-switch
          v-if="dataAvance.length > 0"
          v-model="withAvance"
          checked-children="Payer avec avance"
          un-checked-children="Payer sans
        avance"
          class="mt-3"
        />
      </div>
    </div>
    <!--avance start % -->

    <div class="row m-2" v-if="dataAvance.length > 0 && withAvance">
      <div class="col-md-12">
        <a-divider>Selectionnez les avances a utilisier</a-divider>
      </div>
      <div class="col-md-12">
        <div style="margin-bottom: 16px">
          <span style="margin-left: 8px"> Historique des avances </span>
        </div>
      </div>

      <div class="col-md-9 mycontent-left">
        <a-table
          :columns="columnsAvance"
          :data-source="dataAvance"
          size="middle"
        >
          <template slot="date" slot-scope="text">
            {{ moment(text).format("DD/MM/YYYY") }}
          </template>
        </a-table>
      </div>

      <div class="col-md-3">
        <div style="margin-bottom: 16px">
          <a-badge color="green" dot>
            <a-icon type="notification" />
          </a-badge>

          <span class="h5 font-weight-bold text-success pl-2">
            Solde parent : {{ dataAvance[0].parent.solde }}
          </span>
        </div>
        <div>
          <a-form-item label="Montant avance">
            <a-input-number
              style="width: 100%"
              placeholder="Montant de l'avance"
              v-model="montantAvance"
              place
              :min="0"
              :max="
                total.aReduction > dataAvance[0].parent.solde
                  ? dataAvance[0].parent.solde
                  : total.aReduction
              "
            />
          </a-form-item>
        </div>
        <div v-show="total.aReduction - montantAvance >= 0">
          <span class="bg-light text-dark lead">
            Reste a payer : {{ total.aReduction - montantAvance }}
          </span>
        </div>
      </div>
    </div>
    <!--avance end -->

    <a-form class="card-body row overflow" :form="form">
      <div class="row m-2" v-if="modeName === 'chèque'">
        <div class="col-md-12">
          <a-divider>{{ $t("recette.saisirInfoCheque") }}</a-divider>
        </div>
        <div class="col-md-3">
          <a-form-item label="Numéro:">
            <a-input v-decorator="['cheque.num']" />
          </a-form-item>
        </div>
        <div class="col-md-3">
          <a-form-item label="Date d'échance:">
            <a-date-picker v-decorator="['cheque.dateEchance']" />
          </a-form-item>
        </div>
        <div class="col-md-3">
          <a-form-item label="Banque:">
            <a-input v-decorator="['cheque.bank']" />
          </a-form-item>
        </div>
        <div class="col-md-3">
          <a-form-item label="Titulaire:">
            <a-input v-decorator="['cheque.titulaire']" />
          </a-form-item>
        </div>
      </div>
      <!--Chèque et espéce-->
      <div class="row m-2" v-else-if="modeName === 'Chèque et espéce'">
        <div class="col-md-12">
          <a-divider
            >{{ $t("recette.saisirInfoChequeEtEspace") }} et espéce</a-divider
          >
        </div>
        <div class="col-md-3">
          <a-form-item label="Numéro:">
            <a-input v-decorator="['cheque.num']" />
          </a-form-item>
        </div>
        <div class="col-md-3">
          <a-form-item label="Date d'échance:">
            <a-date-picker v-decorator="['cheque.dateEchance']" />
          </a-form-item>
        </div>
        <div class="col-md-3">
          <a-form-item label="Banque:">
            <a-input v-decorator="['cheque.bank']" />
          </a-form-item>
        </div>
        <div class="col-md-3">
          <a-form-item label="Titulaire:">
            <a-input v-decorator="['cheque.titulaire']" />
          </a-form-item>
        </div>
        <!--les montant-->
        <div class="col-md-6">
          <a-form-item label="Montant chèque:">
            <a-input-number
              style="width: 100%"
              v-decorator="['montantCheque']"
            />
          </a-form-item>
        </div>
        <div class="col-md-6">
          <a-form-item label="Montant espèce">
            <a-input-number
              style="width: 100%"
              v-decorator="['montantEspece']"
            />
          </a-form-item>
        </div>
      </div>
      <div class="col-md-12">
        <a-divider>Selectionnez les frais a payer</a-divider>
      </div>
      <table class="table table-striped col-md-12">
        <thead>
          <th>{{ $t("recette.paiementPour") }}</th>
          <th>{{ $t("recette.restePayer") }}</th>
          <th>{{ $t("recette.payerMain") }}</th>
        </thead>
        <tbody>
          <tr v-if="!data.inscription && data.typeData.inscription > 0">
            <td>{{ $t("recette.inscription") }}</td>
            <td>
              <del v-if="onBillReduction > 0">
                {{ data.typeData.inscription }}
              </del>
              <div v-else>{{ data.typeData.inscription }}</div>
              <sup v-if="data.reduction.inscription > 0" class="text-success"
                >-{{ data.reduction.inscription }}%</sup
              >
              <sup v-if="onBillReduction > 0" class="text-success"
                >-{{ onBillReduction.toFixed(2) }}%</sup
              >
              <div v-if="onBillReduction > 0 || data.reduction.inscription > 0">
                ={{
                  (
                    (data.typeData.inscription -
                      data.typeData.inscription *
                        (data.reduction.inscription / 100)) *
                    ((100 - onBillReduction) / 100)
                  ).toFixed(2)
                }}
              </div>
            </td>
            <td>
              <a-form-item v-if="!data.inscription">
                <a-switch
                  @click="showTotal"
                  v-decorator="[
                    'inscription',
                    {
                      valuePropName: 'checked',
                      initialValue: checked['inscription'],
                    },
                  ]"
                >
                  <a-icon slot="checkedChildren" type="check" />
                  <a-icon slot="unCheckedChildren" type="close" />
                </a-switch>
              </a-form-item>
            </td>
          </tr>
          <tr v-for="(month, key) in data.months" :key="key">
            <td class="pl-2">{{ getMonthName(key) }} ({{ key }})</td>
            <td>
              <div v-if="!data.months[key]">
                {{ data.typeData.monthly }}
                <sup v-if="data.reduction.monthly > 0" class="text-success"
                  >-{{ data.reduction.monthly }}%</sup
                >
                <sup v-if="onBillReduction > 0" class="text-success"
                  >-{{ onBillReduction.toFixed(2) }}%</sup
                >

                <div v-if="onBillReduction > 0 || data.reduction.monthly > 0">
                  ={{
                    (
                      (data.typeData.monthly -
                        data.typeData.monthly *
                          (data.reduction.monthly / 100)) *
                      ((100 - onBillReduction) / 100)
                    ).toFixed(2)
                  }}
                </div>
              </div>
              <div v-else>0</div>
            </td>
            <td>
              <a-form-item v-if="!data.months[key]">
                <a-switch
                  @click="showTotal"
                  :checked="checked[key]"
                  v-decorator="[
                    'months.' + key,
                    {
                      valuePropName: 'checked',
                      initialValue: checked[key],
                    },
                  ]"
                >
                  <a-icon slot="checkedChildren" type="check" />
                  <a-icon slot="unCheckedChildren" type="close" />
                </a-switch>
              </a-form-item>
            </td>
          </tr>
        </tbody>
      </table>
    </a-form>
  </div>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import writtenNumber from "written-number";
import "jspdf-autotable";
import apiClient from "@/services/axios";
import { PageHeader } from "ant-design-vue";
import { mapState } from "vuex";
import JsPDF from "jspdf";
const frMonths = {
  1: "Janvier ",
  2: "Février ",
  3: "Mars ",
  4: "Avril ",
  5: "Mai ",
  6: "Juin ",
  7: "Juillet ",
  8: "Août ",
  9: "Septembre ",
  10: "Octobre ",
  11: "November ",
  12: "Décembre ",
};
const columnsAvance = [
  {
    title: "Date d'ajout",
    dataIndex: "createdAt",
    key: "date",
    scopedSlots: {
      customRender: "date",
    },
  },
  {
    title: "Montant espéce",
    dataIndex: "montantEspece",
    key: "montantEspece",
    sorter: (a, b) => a.montantEspece - b.montantEspece,
  },
  {
    title: "Montant chèque",
    dataIndex: "montantCheque",
    key: "montantCheque",
    sorter: (a, b) => a.montantCheque - b.montantCheque,
  },
  {
    title: "Montant avance",
    dataIndex: "totalAvance",
    key: "totalAvance",
    sorter: (a, b) => a.totalAvance - b.totalAvance,
  },
];
const dataAvance = [];

const getMonthName = (monthString) => {
  const months = {};
  const monthsArr = moment.months();
  for (let i = 0; i < monthsArr.length; i++) months[i + 1] = monthsArr[i];

  return monthString ? months[parseInt(monthString.split("-")[1])] : null;
};
export default {
  computed: {
    ...mapState(["settings"]),
  },
  created() {
    if (this.data.months) {
      for (const key in this.data.months) {
        this.checked[key] = false;
      }
    }
    apiClient
      .post("/parents/filter", {
        query: { _id: this.idParent },
        aggregation: {
          $project: {
            father: 1,
            mother: 1,
          },
        },
      })
      .then((res) => (this.parents = res.data))
      .catch();
    this.originalReduction = { ...this.data.reduction };
  },
  mounted() {
    apiClient
      .get("/avance/" + this.idParent)
      .then((res) => {
        this.dataAvance = res.data.map((e) => {
          return {
            key: e._id,
            ...e,
          };
        });
      })
      .catch((e) => {
        console.error(e.stack);
        this.$message.warning(
          "Un problème du connexion, veuillez rafraîchir la page!4"
        );
      })
      .finally(() => (this.tableLoading = false));
  },
  /*
   */
  beforeDestroy() {
    this.onBillReduction = 0;
  },
  components: {
    "a-page-header": PageHeader,
  },
  props: {
    data: {
      required: true,
    },
    receiptTypes: {
      required: true,
    },
    idParent: {
      required: true,
    },
  },
  data() {
    return {
      loadingValider: false,
      form: this.$form.createForm(this),
      mode: null,
      modeName: null,
      onBillReduction: 0,
      TndOnBillReduction: 0,
      reductionType: process.env.VUE_APP_REDUCTION_TYPE,
      FISCAL: parseFloat(process.env.VUE_APP_FISCAL),
      TVA: parseFloat(process.env.VUE_APP_TVA),
      result: {},
      checked: {
        inscription: false,
      },
      total: {
        bReduction: 0,
        aReduction: 0,
        reduction: 0,
      },
      //avance
      withAvance: false,
      dataAvance,
      columnsAvance,
      loading: false,
      montantAvance: null,
    };
  },
  methods: {
    showTotal(value, reductionChanged = false) {
      let checked = this.form.getFieldsValue();
      this.total.bReduction = 0;
      this.total.aReduction = 0;
      this.total.reduction = 0;
      let monthly = this.data.typeData.monthly;
      let inscription = this.data.typeData.inscription;
      //if reduction type is TND
      if (this.reductionType === "TND") {
        if (checked.inscription) {
          this.total.bReduction += inscription;
          this.total.aReduction +=
            inscription - inscription * (this.data.reduction.inscription / 100);
        }
        for (const key in checked.months) {
          if (checked.months[key]) {
            this.total.bReduction += monthly;
            this.total.aReduction +=
              monthly - monthly * (this.data.reduction.monthly / 100);
          }
        }
        if (!reductionChanged && value) {
          if (value.target.id !== "inscription") {
            if (value.target.checked) {
              this.total.bReduction += monthly;
              this.total.aReduction +=
                monthly - monthly * (this.data.reduction.monthly / 100);
            } else {
              this.total.bReduction -= monthly;
              this.total.aReduction -=
                monthly - monthly * (this.data.reduction.monthly / 100);
            }
          } else {
            if (value.target.checked) {
              this.total.bReduction += inscription;
              this.total.aReduction +=
                inscription -
                inscription * (this.data.reduction.inscription / 100);
            } else {
              this.total.bReduction -= inscription;
              this.total.aReduction -=
                inscription -
                inscription * (this.data.reduction.inscription / 100);
            }
          }
        }
        this.total.aReduction -=
          this.TndOnBillReduction < this.total.aReduction
            ? this.TndOnBillReduction
            : this.total.aReduction;
        this.total.reduction = this.total.bReduction - this.total.aReduction;
        if (this.TndOnBillReduction >= this.total.bReduction)
          this.onBillReduction = 100;
        else if (this.total.bReduction > 0)
          this.onBillReduction =
            100 - (100 * this.total.aReduction) / this.total.bReduction;
        else this.onBillReduction = 0;
      }
      //if reduction type is %
      else {
        if (checked.inscription) {
          this.total.bReduction += inscription;
          this.total.aReduction +=
            (inscription -
              inscription * (this.data.reduction.inscription / 100)) *
            ((100 - this.onBillReduction) / 100);
        }
        for (const key in checked.months) {
          if (checked.months[key]) {
            this.total.bReduction += monthly;
            this.total.aReduction +=
              (monthly - monthly * (this.data.reduction.monthly / 100)) *
              ((100 - this.onBillReduction) / 100);
          }
        }
        if (!reductionChanged && value) {
          if (value.target.id !== "inscription") {
            if (value.target.checked) {
              this.total.bReduction += monthly;
              this.total.aReduction +=
                (monthly - monthly * (this.data.reduction.monthly / 100)) *
                ((100 - this.onBillReduction) / 100);
            } else {
              this.total.bReduction -= monthly;
              this.total.aReduction -=
                (monthly - monthly * (this.data.reduction.monthly / 100)) *
                ((100 - this.onBillReduction) / 100);
            }
          } else {
            if (value.target.checked) {
              this.total.bReduction += inscription;
              this.total.aReduction +=
                (inscription -
                  inscription * (this.data.reduction.inscription / 100)) *
                ((100 - this.onBillReduction) / 100);
            } else {
              this.total.bReduction -= inscription;
              this.total.aReduction -=
                (inscription -
                  inscription * (this.data.reduction.inscription / 100)) *
                ((100 - this.onBillReduction) / 100);
            }
          }
        }

        this.total.reduction = this.total.bReduction - this.total.aReduction;
      }
    },
    moment,
    getMonthName,
    classRoomPFormater(paymentData, bill = false) {
      const reduction = {
        inscription: paymentData.classRoomPayment.reduction.inscription,
        monthly: paymentData.classRoomPayment.reduction.monthly,
      };
      let money = 0;

      if (paymentData.onInscription) {
        const inscriptionFees = parseInt(paymentData.classRoom.inscription);
        money =
          inscriptionFees - (inscriptionFees * reduction.inscription) / 100;
      }
      if (paymentData.months) {
        const nbMonth = Object.keys(paymentData.months).length;
        const monthFees = nbMonth * paymentData.classRoom.monthly;
        money = money + monthFees - (monthFees * reduction.monthly) / 100;
      }
      const onBillReduction = paymentData.onBillReduction
        ? paymentData.onBillReduction
        : 0;
      money = money - (money * onBillReduction) / 100;
      const parent = this.parents.filter(
        (e) => e._id == paymentData.student.parent
      )[0];
      const id = paymentData._id;
      let data = {
        serial: paymentData.serial,
        url: bill ? "/bills/classroom/" + id : "/receipt/classroom/" + id,
        _id: id,
        nomClient:
          parent.mother.firstName +
          " " +
          parent.mother.lastName +
          " ET " +
          parent.father.firstName +
          " " +
          parent.father.lastName,
        classRoom: paymentData.classRoom,
        student: paymentData.student,
        typeReglement: "Scolarité/" + paymentData.classRoom.name,
        modePaiement: paymentData.receiptType.name,
        montantHT: money - money * this.TVA,
        montantTTC: money + (bill ? this.FISCAL : 0),
        classRoomPayment: paymentData.classRoomPayment,
        bill: bill,
        date: paymentData.date,
        info: {
          onInscription: Boolean(paymentData.onInscription),
          months: paymentData.months,
        },
      };
      return data;
    },
    activityFormater(paymentData, bill = false) {
      const reduction = {
        inscription: paymentData.activityfees.reduction.inscription,
        monthly: paymentData.activityfees.reduction.monthly,
      };
      let money = 0;
      if (paymentData.onInscription) {
        const inscriptionFees = parseInt(paymentData.activity.inscription);
        money =
          inscriptionFees - (inscriptionFees * reduction.inscription) / 100;
      }
      if (paymentData.months) {
        const nbMonth = Object.keys(paymentData.months).length;
        const monthFees = nbMonth * paymentData.activity.monthly;
        money = money + monthFees - (monthFees * reduction.monthly) / 100;
      }
      const onBillReduction = paymentData.onBillReduction
        ? paymentData.onBillReduction
        : 0;
      money = money - (money * onBillReduction) / 100;
      const parent = this.parents.filter(
        (e) => e._id == paymentData.student.parent
      )[0];
      const id = paymentData._id;
      let data = {
        serial: paymentData.serial,
        url: bill ? "/bills/activity/" + id : "/receipt/activity/" + id,
        _id: id,
        nomClient:
          parent.mother.firstName +
          " " +
          parent.mother.firstName +
          " ET " +
          parent.father.firstName +
          " " +
          parent.father.firstName,
        typeReglement: "Activité/" + paymentData.activity.designation,
        modePaiement: paymentData.receiptType.name,
        activity: paymentData.activity,
        student: paymentData.student,
        montantHT: money - money * this.TVA,
        montantTTC: money + (bill ? this.FISCAL : 0),
        activityfees: paymentData.activityfees,
        bill: bill,
        date: paymentData.date,
        info: {
          onInscription: Boolean(paymentData.onInscription),
          months: paymentData.months,
        },
      };
      return data;
    },
    checkEmALL() {
      let months = {};
      for (const key in this.checked) {
        this.checked[key] = !this.checked[key];
        if (key == "inscription")
          this.form.setFieldsValue({ inscription: this.checked[key] });
        else {
          months[key] = this.checked[key];
        }
        this.form.setFieldsValue({ months: months });
      }
      this.showTotal(null, false);
    },
    getUrl(paymentType, paymentMode) {
      const types = paymentMode == "Receipt" ? "receipt" : "bill";
      const to = paymentType == "activity" ? "activity" : "classroom";
      return `/students/${to}/${types}`;
    },

    notZeroAmount(months, inscription) {
      if (inscription) return true;
      let result = false;
      if (!inscription || inscription == undefined) result = false;
      if (!months || months == undefined) result = false;
      else
        Object.values(months).forEach((month) => {
          if (month) result = true;
        });
      return result;
    },
    validPayment(type) {
      this.loadingValider = true;
      //this.updateReduction();
      if (!this.mode) this.$message.warning("Selectionner le mode de paiement");
      else
        this.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            this.loadingValider = true;
            let data = {
              onBillReduction: this.onBillReduction,
            };
            if (!this.notZeroAmount(values.months, values.inscription)) {
              this.$message.error(
                "vous ne pouvez pas valider un paiement d'un montant égal à zéro"
              );
              this.loadingValider = false;
              return;
            }
            if (values.months) {
              data.months = {};
              Object.keys(values.months).forEach((month) => {
                if (values.months[month]) data.months[month] = true;
              });
            }
            if (values.inscription) data["onInscription"] = true;
            //if payment mode is cheque et espéce
            if (this.modeName === "Chèque et espéce") {
              if (
                this.total.aReduction.toFixed(2) !=
                values.montantCheque + values.montantEspece
              ) {
                this.$message.warning(
                  "La somme des montants chèque et espèce doit être égale à " +
                    this.total.aReduction.toFixed(2)
                );
                 this.loadingValider = false;
                return      

              }
              data.montantCheque = values.montantCheque;
              data.montantEspece = values.montantEspece;
            }
            if (this.data.type == "activity") data.activityFees = this.data._id;
            else data.classRoomPayment = this.data._id;
            data.receiptType = this.mode;
            data.date = Date.now();
            if (values.cheque && values.cheque.num) data.cheque = values.cheque;

            apiClient
              .post(this.getUrl(this.data.type, type.key), data)
              .then(async (res) => {
                if (this.withAvance && this.montantAvance > 0) {
                  let avanceData = {
                    receiptType: data.receiptType,
                    parent: this.idParent,
                    totalAvance: -this.montantAvance,
                    typeReglement:
                      this.data.type == "activity"
                        ? "Activité/" + this.data.typeData.designation
                        : "Scolarité/" + this.data.typeData.name,
                    used: true,
                  };
                  await apiClient
                    .put("/avance", avanceData)
                    .then((res) => {
                      this.$message.success(
                        "Avance débiter du solde parent avec succées"
                      );
                    })
                    .catch((e) => {
                      console.error(e);
                      this.$message.error(e.response.data.message);
                    });
                }
                this.$message.success("Paiment ajouté");
                this.generatePDF(
                  res.data._id,
                  this.data.type,
                  this.onBillReduction,
                  type.key
                );
                this.$emit("reload");
              })
              .catch((e) => {
                console.log(e);
                this.$message.warning("Paiement non effectuer");
              })
              .finally(() => (this.loadingValider = false));
          } else {
            this.$message.warning("Paiement non effectuer");
            this.loadingValider = false;
          }
        });
    },
    async getPaymentData(url, _id, classRoom, bill) {
      const getAggregation = (classRoom, bill) => {
        if (classRoom) {
          if (bill)
            return [
              {
                $lookup: {
                  from: "classroompayments",
                  localField: "classRoomPayment",
                  foreignField: "_id",
                  as: "classRoomPayment",
                },
              },
              {
                $lookup: {
                  from: "students",
                  localField: "classRoomPayment.student",
                  foreignField: "_id",
                  as: "student",
                },
              },
              {
                $lookup: {
                  from: "classrooms",
                  localField: "classRoomPayment.classRoom",
                  foreignField: "_id",
                  as: "classRoom",
                },
              },
              {
                $lookup: {
                  from: "receipttypes",
                  localField: "receiptType",
                  foreignField: "_id",
                  as: "receiptType",
                },
              },
              {
                $project: {
                  _id: 1,
                  date: 1,
                  serial: 1,
                  months: 1,
                  onBillReduction: 1,
                  onInscription: 1,
                  student: {
                    firstName: 1,
                    lastName: 1,
                    parent: 1,
                  },
                  classRoom: {
                    _id: 1,
                    name: 1,
                    monthly: 1,
                    inscription: 1,
                  },
                  classRoomPayment: {
                    _id: 1,
                    reduction: 1,
                    inscription: 1,
                    months: 1,
                  },
                  receiptType: { name: 1 },
                },
              },
            ];
          else
            return [
              {
                $lookup: {
                  from: "classroompayments",
                  localField: "classRoomPayment",
                  foreignField: "_id",
                  as: "classRoomPayment",
                },
              },
              {
                $lookup: {
                  from: "students",
                  localField: "classRoomPayment.student",
                  foreignField: "_id",
                  as: "student",
                },
              },
              {
                $lookup: {
                  from: "classrooms",
                  localField: "classRoomPayment.classRoom",
                  foreignField: "_id",
                  as: "classRoom",
                },
              },
              {
                $lookup: {
                  from: "receipttypes",
                  localField: "receiptType",
                  foreignField: "_id",
                  as: "receiptType",
                },
              },
              {
                $project: {
                  _id: 1,
                  date: 1,
                  serial: 1,
                  months: 1,
                  onBillReduction: 1,
                  onInscription: 1,
                  student: {
                    firstName: 1,
                    lastName: 1,
                    parent: 1,
                  },
                  classRoom: {
                    _id: 1,
                    name: 1,
                    monthly: 1,
                    inscription: 1,
                  },
                  classRoomPayment: {
                    _id: 1,
                    reduction: 1,
                    inscription: 1,
                    months: 1,
                  },
                  receiptType: { name: 1 },
                },
              },
            ];
        } else {
          if (bill)
            return [
              {
                $lookup: {
                  from: "activityfees",
                  localField: "activityFees",
                  foreignField: "_id",
                  as: "activityfees",
                },
              },
              {
                $lookup: {
                  from: "students",
                  localField: "activityfees.student",
                  foreignField: "_id",
                  as: "student",
                },
              },
              {
                $lookup: {
                  from: "activities",
                  localField: "activityfees.activity",
                  foreignField: "_id",
                  as: "activity",
                },
              },
              {
                $lookup: {
                  from: "receipttypes",
                  localField: "receiptType",
                  foreignField: "_id",
                  as: "receiptType",
                },
              },
              {
                $project: {
                  _id: 1,
                  date: 1,
                  serial: 1,
                  months: 1,
                  onBillReduction: 1,
                  onInscription: 1,
                  student: {
                    firstName: 1,
                    lastName: 1,
                    parent: 1,
                  },
                  activity: {
                    _id: 1,
                    designation: 1,
                    monthly: 1,
                    inscription: 1,
                  },
                  activityfees: {
                    _id: 1,
                    reduction: 1,
                    inscription: 1,
                    months: 1,
                  },
                  receiptType: { name: 1 },
                },
              },
            ];
          else
            return [
              {
                $lookup: {
                  from: "activityfees",
                  localField: "activityFees",
                  foreignField: "_id",
                  as: "activityfees",
                },
              },
              {
                $lookup: {
                  from: "students",
                  localField: "activityfees.student",
                  foreignField: "_id",
                  as: "student",
                },
              },
              {
                $lookup: {
                  from: "activities",
                  localField: "activityfees.activity",
                  foreignField: "_id",
                  as: "activity",
                },
              },
              {
                $lookup: {
                  from: "receipttypes",
                  localField: "receiptType",
                  foreignField: "_id",
                  as: "receiptType",
                },
              },
              {
                $project: {
                  _id: 1,
                  date: 1,
                  serial: 1,
                  months: 1,
                  onBillReduction: 1,
                  onInscription: 1,
                  student: {
                    firstName: 1,
                    lastName: 1,
                    parent: 1,
                  },
                  activity: {
                    _id: 1,
                    designation: 1,
                    monthly: 1,
                    inscription: 1,
                  },
                  activityfees: {
                    _id: 1,
                    reduction: 1,
                    inscription: 1,
                    months: 1,
                  },
                  receiptType: { name: 1 },
                },
              },
            ];
        }
      };
      await apiClient
        .post(url, {
          query: { _id: _id },
          aggregation: getAggregation(classRoom, bill),
        })
        .then((res) => {
          const elem = res.data[0];
          if (classRoom)
            this.result = this.classRoomPFormater(
              {
                ...elem,
                classRoom: elem.classRoom[0],
                classRoomPayment: elem.classRoomPayment[0],
                receiptType: elem.receiptType[0],
                student: elem.student[0],
              },
              bill
            );
          else
            this.result = this.activityFormater(
              {
                ...elem,
                activity: elem.activity[0],
                activityfees: elem.activityfees[0],
                receiptType: elem.receiptType[0],
                student: elem.student[0],
              },
              bill
            );
        })
        .catch((e) => {
          this.$message.warning("Erreur lors de la génération du PDF");
        });
    },
    async generatePDF(_id, paymentType, onBillReduction, paymentMode) {
      await this.getPaymentData(
        `/money/${paymentType == "classRoom" ? "classroom" : "activity"}${
          paymentMode == "Bill" ? "bill" : "receipt"
        }/filter`,
        _id,
        paymentType == "classRoom",
        paymentMode == "Bill"
      );
      let record = this.result;

      const tva = 0.07;
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();

      var titre = record.bill
        ? "Facture N. " + record.serial.split(":")[1]
        : "Reçue N. " + record.serial.split("/")[1];
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      doc.setFontSize(15);
      doc.text(80, 60, titre);
      doc.text(20, 70, "Nom et Prénom :" + record.nomClient);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      let reduction = {};
      if (!record.activity)
        reduction = {
          inscription: record.classRoomPayment.reduction.inscription,
          monthly: record.classRoomPayment.reduction.monthly,
        };
      onBillReduction = onBillReduction ? onBillReduction / 100 : 0;
      if (record.info.onInscription) {
        arr.push(record.student.lastName + " " + record.student.firstName);
        arr.push("Inscription ");
        arr.push(record.typeReglement);
        //record.activity?arr.push(record.activity.inscription):arr.push(record.classRoom.inscription);
        record.activity
          ? arr.push(
              record.bill === true
                ? record.activity.inscription -
                    record.activity.inscription * tva
                : record.activity.inscription
            )
          : arr.push(
              record.bill
                ? record.classRoom.inscription -
                    record.classRoom.inscription * tva
                : record.classRoom.inscription -
                    (record.classRoom.inscription * reduction.inscription) / 100
            );
        //arr[arr.length - 1] -= arr[arr.length - 1] * onBillReduction;

        array.push(arr);
        arr = [];
      }
      if (record.info.months) {
        Object.keys(record.info.months).forEach((k) => {
          arr.push(record.student.lastName + " " + record.student.firstName);

          let paymentDate = new Date(k);
          let monthName = paymentDate.toLocaleString("fr-FR", {
            month: "long",
          });
          arr.push(monthName);
          arr.push(record.typeReglement);

          record.activity
            ? arr.push(
                record.bill === true
                  ? record.activity.monthly - record.activity.monthly * tva
                  : record.activity.monthly
              )
            : arr.push(
                record.bill
                  ? record.classRoom.monthly - record.classRoom.monthly * tva
                  : record.classRoom.monthly -
                      (record.classRoom.monthly * reduction.monthly) / 100
              );
          //  arr[arr.length - 1] -= arr[arr.length - 1] * onBillReduction;
          array.push(arr);
          arr = [];
        });
      }
      let rowData = [
        record.montantHT,
        record.montantTTC - record.montantHT - this.FISCAL,
        this.FISCAL,
        record.montantTTC,
      ];
      //affichier reduction si existe
      if (onBillReduction) {
        arr.push({
          colSpan: 3,
          content: "Réduction",
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        arr.push({
          content: (onBillReduction * 100).toFixed(2) + "%",
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        array.push(arr);
        arr = [];
      }
      let rowDataText = ["Total HTVA", "TVA", "Timbre", "Total TTC"];
      for (let i = 0; i < 4; i++) {
        if (!record.bill) {
          i = 3;
          rowDataText[i] = "Total";
        }
        arr.push({
          colSpan: 3,
          content: rowDataText[i],
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        arr.push({
          content: parseFloat(rowData[i]).toFixed(2),
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        array.push(arr);
        arr = [];
      }
      doc.autoTable({
        styles: {
          lineWidth: 0.2,
          valign: "middle",
          halign: "center",
          fontStyle: "bold",
          font: "Amiri",
        },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [["Enfant", "Frais", "Désignation", "Montant"]],
        body: array,
        foot: [
          [
            {
              colSpan: 4,
              content:
                "Le montant total est " +
                writtenNumber(record.montantTTC, { lang: "fr" }) +
                " DT",
              styles: { halign: "center" },
            },
          ],
        ],
      });
      doc.save(titre + ".pdf");
    },
    selectMode(mode, e) {
      this.modeName = e.data.attrs.name;
      this.mode = mode;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    //avance methodes
  },
};
</script>
<style scoped>
td {
  padding: 0%;
}
.center {
  margin: auto;
  width: 50%;
}
.mycontent-left {
  border-right: 1px dashed #333;
}
</style>
<style>
.ant-switch-inner {
  font-size: 18px !important;
}
</style>
