<template>
  <div class="card">
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      class="card-header"
      :title="data.classRoom.name"
    >
      <div class="row">
        <div class="col-md-4 offset-md-4">
          <b>{{ $t("all.startDate") }}: </b
          >{{ moment(data.classRoom.start).format("DD-MM-YYYY") }}
        </div>
        <div class="col-md-4">
          <b>{{ $t("all.endDate") }}: </b
          >{{
            data.classRoom.start
              ? moment(data.classRoom.end).format("DD-MM-YYYY")
              : "-"
          }}
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-4">
          <b>{{ $t("paiement.fraisInscri") }}: </b
          >{{ data.classRoom.inscription }}DT
        </div>
        <div class="col-md-4">
          <b>{{ $t("paiement.fraisMensuel") }}: </b
          >{{ data.classRoom.monthly + "DT" }}
        </div>
        <div class="col-md-4">
          <a-select
            @change="selectMode"
            show-search
            style="width: 100%"
            option-filter-prop="children"
            :placeholder="$t('recette.typePaiement')"
            :filter-option="filterOption"
          >
            <a-select-option
              :value="item._id"
              v-for="item in receiptTypes"
              :key="item._id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </a-page-header>
    <div class="card-body">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>{{ $t("recette.paiementPour") }}</th>
            <th>{{ $t("recette.restePayer") }}</th>
            <th>{{ $t("recette.payer") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!data.inscription">
            <td>{{ $t("recette.inscription") }}</td>
            <td>
              <del v-if="data.reduction.inscription > 0">
                {{ data.classRoom.inscription }}
              </del>
              <div v-else>{{ data.classRoom.inscription }}</div>
              <sup v-if="data.reduction.inscription > 0" class="text-success"
                >-{{ data.reduction.inscription }}%</sup
              >
              <div v-if="data.reduction.inscription > 0">
                ={{
                  data.classRoom.inscription -
                  (data.classRoom.inscription * data.reduction.inscription) /
                    100
                }}
              </div>
            </td>
            <td>
              <a-dropdown :disabled="true" class="col-md-5 -offset-2">
                <a-menu
                  slot="overlay"
                  @click="
                    (v) => {
                      validPayment(v, {
                        onInscription: true,
                        monthKey: null,
                      });
                    }
                  "
                >
                  <a-menu-item key="Receipt">
                    <a-icon type="user" />{{
                      $t("recette.recuPaiement")
                    }}</a-menu-item
                  >
                  <a-menu-item key="Bill">
                    <a-icon type="user" />{{
                      $t("recette.facturePaiement")
                    }}</a-menu-item
                  >
                </a-menu>
                <a-button style="margin-left: 8px">
                  {{ $t("recette.valider") }} <a-icon type="down" />
                </a-button>
              </a-dropdown>
            </td>
          </tr>
          <tr v-for="(month, key) in data.months" :key="key">
            <td>{{ getMonthName(key) }} ({{ key }})</td>
            <td>
              <div v-if="!data.months[key]">
                {{ data.classRoom.monthly }}
                <sup v-if="data.reduction.monthly > 0" class="text-success"
                  >-{{ data.reduction.monthly }}%</sup
                >
                <div v-if="data.reduction.monthly > 0">
                  ={{
                    data.classRoom.monthly -
                    (data.classRoom.monthly * data.reduction.monthly) / 100
                  }}
                </div>
              </div>
              <div v-else>0</div>
            </td>
            <td>
              <a-dropdown :disabled="true">
                <a-menu
                  slot="overlay"
                  @click="
                    (v) => {
                      validPayment(v, {
                        onInscription: false,
                        monthKey: key,
                      });
                    }
                  "
                >
                  <a-menu-item key="Receipt">
                    <a-icon type="file-pdf" />{{
                      $t("recette.recuPaiement")
                    }}</a-menu-item
                  >
                  <a-menu-item key="Bill">
                    <a-icon type="file-pdf" />{{
                      $t("recette.facturePaiement")
                    }}</a-menu-item
                  >
                </a-menu>
                <a-button style="margin-left: 8px">
                  {{ $t("recette.valider") }} <a-icon type="down" />
                </a-button>
              </a-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import { PageHeader } from "ant-design-vue";
import moment from "moment";

const frMonths = {};
const months = moment.months();
for (let i = 0; i < months.length; i++) frMonths[i + 1] = months[i];

const getMonthName = (monthString) => {
  return monthString ? frMonths[parseInt(monthString.split("-")[1])] : null;
};
export default {
  created() {},
  components: {
    "a-page-header": PageHeader,
  },
  props: {
    data: {
      required: true,
    },
    receiptTypes: {
      required: true,
    },
  },
  data() {
    return {
      selectedMode: { active: false, val: null },
    };
  },
  methods: {
    moment,
    getMonthName,
    selectMode(v) {
      this.selectedMode = { active: true, val: v };
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    validPayment(paymentType, what) {
      const url = `/students/classroom/${
        paymentType == "Receipt" ? "receipt" : "bill"
      }`;
      const paymentMode = this.selectedMode.val;
      let data = what.monthKey
        ? {
            onInscription: false,
            date: Date.now(),
            receiptType: paymentMode,
            months: what.monthKey,
            classRoomPayment: this.data._id,
          }
        : {
            onInscription: true,
            date: Date.now(),
            receiptType: paymentMode,
            classRoomPayment: this.data._id,
          };
      apiClient
        .post(url, data)
        .then((res) => {
          this.$message.success(this.$t("success.paiementAjout"));
          // update current modal
          if (what.onInscription) this.data.inscription = true;
          else this.data.months[what.monthKey] = true;
          //update the parent
          this.$emit("updateClassRoomPayments", {
            _id: this.data._id,
            onInscription: what.onInscription,
            month: what.monthKey,
          });
        })
        .catch((e) => this.$message.warning(this.$t("error.ajout")));
    },
  },
};
</script>
